<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <h2>ข้อมูลส่วนตัว</h2>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
        >
          <v-row>
            <v-col
              v-if="editedItem.member_id ? editedItem.member_img_logo : editedItem.employee_img"
              cols="12"
              sm="12"
              md="12"
            >
              <v-img
                :src="editedItem.member_id ? editedItem.member_img_logo : editedItem.employee_img"
              >
              </v-img>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-file-input
                v-model="editedItem.file"
                accept="image/png, image/jpeg, image/bmp"
                label="โลโก้ร้าน"
              >
              </v-file-input>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_shop_name"
                type="text"
                label="ชื่อร้าน"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_shop_name"
                type="text"
                label="ชื่อร้าน"
              ></v-text-field>
              <small
                v-for="(err,index) in v$.editedItem.member_shop_name.$silentErrors"
                v-if="v$.editedItem.member_shop_name.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_email"
                type="email"
                label="อีเมลเข้าใช้งาน"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_email"
                type="email"
                label="อีเมลเข้าใช้งาน"
              ></v-text-field>
              <small
                v-for="(err,index) in v$.editedItem.member_email.$silentErrors"
                v-if="v$.editedItem.member_email.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
              <small
                v-for="(err,index) in v$.editedItem.employee_email.$silentErrors"
                v-if="v$.editedItem.employee_email.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.password"
                type="password"
                label="รหัสผ่าน"
              ></v-text-field>
              <small
                v-for="(err,index) in v$.editedItem.password.$silentErrors"
                v-if="v$.editedItem.password.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.password_confirmation"
                type="password"
                label="ยืนยันรหัสผ่าน"
              ></v-text-field>
              <small
                v-for="(err,index) in v$.editedItem.password_confirmation.$silentErrors"
                v-if="v$.editedItem.password_confirmation.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_name"
                type="text"
                label="ชื่อ-นามสกุล"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_name"
                type="text"
                label="ชื่อ-นามสกุล"
              ></v-text-field>

              <small
                v-for="(err,index) in v$.editedItem.member_name.$silentErrors"
                v-if="v$.editedItem.member_name.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
              <small
                v-for="(err,index) in v$.editedItem.employee_name.$silentErrors"
                v-if="v$.editedItem.employee_name.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_tele"
                label="เบอร์โทรศัพท์"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_tele"
                label="เบอร์โทรศัพท์"
              ></v-text-field>

              <small
                v-for="(err,index) in v$.editedItem.member_tele.$silentErrors"
                v-if="v$.editedItem.member_tele.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
              <small
                v-for="(err,index) in v$.editedItem.employee_tele.$silentErrors"
                v-if="v$.editedItem.employee_tele.$error"
                class="validate-err"
              >{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_line_id"
                label="Line Id"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_line_id"
                label="Line Id"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-if="editedItem.member_id"
                v-model="editedItem.member_shop_address"
                label="ที่อยู่"
              ></v-textarea>
              <v-textarea
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_shop_address"
                label="ที่อยู่"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_facebook_url"
                label="Facebook URL"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_facebook_url"
                label="Facebook URL"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_instragram"
                label="Instagram"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_instragram"
                label="Instagram"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-if="editedItem.member_id"
                v-model="editedItem.member_identification"
                label="เลขบัตรประชาชน"
              ></v-text-field>
              <v-text-field
                v-if="!editedItem.member_id"
                v-model="editedItem.employee_identification"
                label="เลขบัตรประชาชน"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="12"
          sm="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="updateProfile"
          >
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import Datepicker from 'vue2-datepicker'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import instance_user from '@/services/user'
import instance_employee from '@/services/employee'

import instance_main_data from '../../../services/mainData'
import instance_member from '@/services/member'

export default {
  components: {
    Datepicker,
  },
  setup() {
    const tab = ref('')

    return {
      v$: useVuelidate(),

      // tab,
      // tabs,
      // accountSettingData,
      // icons: {
      //   mdiAccountOutline,
      //   mdiLockOpenOutline,
      //   mdiInformationOutline,
      // },
    }
  },
  data() {
    return {
      editedItem: {
        member_shop_name: '',
        member_name: '',
        member_email: '',
        member_tele: '',
        id: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  validations() {
    const validate = {
      editedItem: {
        member_shop_name: {},
        member_name: {},
        member_email: {},
        member_tele: {},
        employee_name: {},
        employee_email: {},
        employee_tele: {},
        password: {},
        password_confirmation: {},

        // main_data_name: {required},
        // main_data_title_web: {required},
        // main_data_name_domain: { required },
        // main_data_email: { required , email },
        // main_data_address: { required },
      },
    }

    if (this.editedItem.member_id) {
      validate.editedItem.member_shop_name = { required }
      validate.editedItem.member_name = { required }
      validate.editedItem.member_email = { required }
      validate.editedItem.member_tele = { required }
    } else {
      validate.editedItem.employee_name = { required }
      validate.editedItem.employee_email = { required }
      validate.editedItem.employee_tele = { required }
    }

    return validate
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    async updateProfile() {
      if (this.editedItem.member_id) {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
        this.$store.state.isLoading = true
        console.log(this.editedItem, 1111)
        instance_member.memberUpdate(this.editedItem).then(res => {
          this.getProfile()
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.$store.state.isLoading = false
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

          this.$store.state.isLoading = false
        })
      } else {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
        this.$store.state.isLoading = true
        instance_employee.employeeUpdate(this.editedItem).then(res => {
          this.getProfile()
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.$store.state.isLoading = false
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

          this.$store.state.isLoading = false
        })
      }
    },
    getProfile() {
      this.$store.state.isLoading = true
      instance_user.getProfile().then(res => {
        this.editedItem = res.data.user
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>
